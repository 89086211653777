import { SET_USER, UPDATE_USER, LOGOUT_USER } from "./actions"

export function userReducer(state, action = {}) {
  if (action.type ?? false)
    switch (action.type) {
      case SET_USER:
        return action.payload
      case UPDATE_USER:
        return { ...state, ...action.payload }
      case LOGOUT_USER:
        return action.payload
      /* case MARK:
      return state.map((task, i) => {
        if (i === action.payload.taskId)
          return { ...task, done: action.payload.done }

        return task
      })
    case DELETE:
      return state.filter((_task, i) => action.payload.taskId !== i) */
      default:
        return state
    }
}
