/* eslint-disable no-extra-semi */
/* eslint-disable react/prop-types */
import React, { useReducer, useEffect } from "react"
import { userReducer } from "./reducer"
import { setUserAction, logoutUserAction, updateUserAction } from "./actions"
import { isBrowser } from "settings"
import jwt from "jsonwebtoken"

/*
import Axios from "axios"

const validateToken = async token => {
  let response
  try {
    response = await Axios.post(
      `${NEXT_PUBLIC_API_URL}/users/me`,
      {},
      {
        headers: { Authorization: "Bearer " + token },
      }
    )
  } catch (err) {
    console.error(err)
    return false
  }
  const data = await response.data
  return { ...data, token }
}
*/

export const UserContext = React.createContext(null)
export const UserProvider = ({ children }) => {
  const [user, dispatch] = useReducer(userReducer, undefined)

  useEffect(() => {
    ;(async () => {
      const loggedUser =
        isBrowser() && window.localStorage.getItem("user") ? JSON.parse(window.localStorage.getItem("user")) : undefined

      const token = isBrowser() ? window.localStorage.getItem("token") : undefined
      const tokenExpiration = isBrowser() ? window.localStorage.getItem("tokenExpiration") : undefined

      // * verifico che il token non sia scaduto
      if (!token || (tokenExpiration && tokenExpiration * 1000 < new Date().getTime())) {
        window.localStorage.removeItem("user")
        window.localStorage.removeItem("token")
        window.localStorage.removeItem("tokenExpiration")
        // dispatch(logoutUserAction())

        await dispatch(setUserAction(false))
      } else {
        // @todo check JWT validity w/ API
        await dispatch(setUserAction({ ...loggedUser, token, tokenExpiration }))
      }
    })()
  }, [])

  useEffect(() => {}, [user])

  /**
   * * ACTIONS AVAILABLE ON CONTEXT CONSUMER
   */
  const exportedActions = {
    setUser(userData, token) {
      const decodedToken = jwt.decode(token, { complete: true })
      const tokenExpiration = decodedToken?.payload?.exp

      if (isBrowser()) {
        window.localStorage.setItem("user", JSON.stringify(userData))
        window.localStorage.setItem("token", token)
        window.localStorage.setItem("tokenExpiration", tokenExpiration)
      }
      dispatch(setUserAction({ ...userData, token, tokenExpiration }))
    },
    updateUser(userData) {
      if (isBrowser()) {
        window.localStorage.setItem("user", JSON.stringify(userData))
      }
      dispatch(updateUserAction({ ...userData }))
    },
    logoutUser() {
      window.localStorage.removeItem("user")
      window.localStorage.removeItem("token")
      window.localStorage.removeItem("tokenExpiration")
      dispatch(logoutUserAction())
    },
  }

  /*

  const getUser = () => {
    async function fetchUser() {
      if (isBrowser() && window.localStorage.getItem("user")) {
        const localStorageUser = JSON.parse(
          window.localStorage.getItem("user")
        )
        const user = localStorageUser.token
          ? await validateToken(localStorageUser.token)
          : false

        console.log("📺 getUser -> user", user)

        return user
      } else {
        return false
      }
    }

    const user = fetchUser()
    if (!user) {
      window.localStorage.removeItem("user")
    }

    return user
  }
  */

  /* const getUserOri = () => {
    return isBrowser() && window.localStorage.getItem("user")
      ? JSON.parse(window.localStorage.getItem("user"))
      : undefined
  } */

  return <UserContext.Provider value={{ user, actions: exportedActions }}>{children}</UserContext.Provider>
}
