import Axios from "axios"
import { NEXT_PUBLIC_API_URL } from "settings"

export const SET_USER = "SET_USER"
export const UPDATE_USER = "UPDATE_USER"
export const LOGOUT_USER = "LOGOUT_USER"

export function setUserAction(userData) {
  return {
    type: SET_USER,
    payload: userData,
  }
}

export function updateUserAction(userData) {
  return {
    type: UPDATE_USER,
    payload: userData,
  }
}

export function logoutUserAction() {
  return {
    type: LOGOUT_USER,
    payload: false,
  }
}

export const saveUserHelper = (userData, user) => {
  return Axios.put(`${NEXT_PUBLIC_API_URL}/users/me`, userData, {
    headers: { Authorization: `Bearer ${user.token}` },
  })
    .then((response) => response.data)
    .catch((error) => {
      console.error("An error occurred:", error)
      return { error }
    })
}
